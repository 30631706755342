import { loader } from "@monaco-editor/react";
import * as Sentry from "@sentry/react";
import { EXPECTED_ERROR_TYPES, ZuploError } from "errors";
import { createBrowserHistory } from "history";
import log from "loglevel";
import React, { lazy } from "react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { SENTRY_DSN, SENTRY_ENV, SENTRY_RELEASE } from "utils/env";

import "./index.css";
// Knock notifications styles
import "@knocklabs/react/dist/index.css";

loader.config({
  paths: {
    vs: "https://cdn.jsdelivr.net/npm/monaco-editor@0.36.0/min/vs",
  },
});

log.setDefaultLevel("silent");

if (import.meta.env.DEV) {
  log.setLevel("debug");
} else if (localStorage.getItem("ENABLE_DEBUG_LOGS")) {
  log.setLevel("debug");
}

declare global {
  interface Window {
    zuploEnableSupportLogs: () => void;
    zuploDisableSupportLogs: () => void;
  }
}

window.zuploEnableSupportLogs = () => {
  localStorage.setItem("ENABLE_DEBUG_LOGS", "1");
  log.setDefaultLevel("debug");
  log.info(
    "Support logging is enabled. To disable run `window.zuploDisableSupportLogs()`",
  );
};

window.zuploDisableSupportLogs = () => {
  localStorage.removeItem("ENABLE_DEBUG_LOGS");
  log.info("Support logging is disabled.");
  log.setLevel("silent");
};

const history = createBrowserHistory();

if (SENTRY_DSN) {
  Sentry.init({
    autoSessionTracking: true,
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,

    tracePropagationTargets: [
      "localhost",
      /^https:\/\/api\.zuplo\.com/,
      /^https:\/\/api-staging\.zuplo\.com/,
    ],

    integrations: [
      // Add browser profiling integration to the list of integrations
      Sentry.browserProfilingIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    release: SENTRY_RELEASE,

    beforeSend: (event, hint) => {
      if (
        hint.originalException &&
        hint.originalException instanceof ZuploError
      ) {
        if (EXPECTED_ERROR_TYPES.includes(hint.originalException.type)) {
          return null;
        }
      }
      return event;
    },
  });
}

// This needs to be AFTER the sentry initialization
const Bootstrap = lazy(() => import("./Bootstrap"));

const Profiled = Sentry.withProfiler(Bootstrap);
const container = document.getElementById("zuplo-portal");
const root = createRoot(container!);
root.render(<Profiled history={history} />);

try {
  // Unregister previous service workers
  if ("serviceWorker" in navigator) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (navigator.serviceWorker != null) {
      void navigator.serviceWorker
        .getRegistrations()
        .then(async function (registrations) {
          for (const registration of registrations) {
            registration.unregister().catch(function (err) {
              Sentry.captureException(err);
            });
          }
        });
    }
  }
} catch (error) {
  Sentry.captureException(error);
}
